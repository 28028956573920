const Profile = [
	{ 
		id: 1, 
		name: '/assets/images/avatar/useremoji/Avatar1.png', 
	},
	{ 
		id: 2, 
		name: '/assets/images/avatar/useremoji/Avatar2.png', 
	},
	{ 
		id: 3, 
		name: '/assets/images/avatar/useremoji/Avatar3.png', 
	},
	{ 
		id: 4, 
		name: '/assets/images/avatar/useremoji/Avatar4.png', 
	},
	{ 
		id: 5, 
		name: '/assets/images/avatar/useremoji/Avatar5.png', 
	},
	{ 
		id: 6, 
		name: '/assets/images/avatar/useremoji/Avatar6.png', 
	},
	{ 
		id: 7, 
		name: '/assets/images/avatar/useremoji/Avatar7.png', 
	},
	{ 
		id: 8, 
		name: '/assets/images/avatar/useremoji/Avatar8.png', 
	},
	{ 
		id: 9, 
		name: '/assets/images/avatar/useremoji/Avatar9.png', 
	},
	{ 
		id: 10, 
		name: '/assets/images/avatar/useremoji/Avatar10.png', 
	},
	{ 
		id: 11, 
		name: '/assets/images/avatar/useremoji/Avatar11.png', 
	},
	{ 
		id: 12, 
		name: '/assets/images/avatar/useremoji/Avatar12.png', 
	},
  ];
  
export default Profile;