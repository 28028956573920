
import './landing.css'
import SectionOne from "../../component/landingpage/contact/sectionone"
import SectionFour from '../../component/landingpage/home/sectionfive';
import SectionTwo from '../../component/landingpage/contact/sectiontwo';


export default function Contact() {


	return ( 
	<>		
		<SectionOne />
		<SectionTwo />
		<SectionFour />

	</>

  );
}
